import React from 'react'
import Logo from '../Assets/newlog.png'
import '../Stylesheets/Footer.scss'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import XIcon from '@mui/icons-material/X';
// import Twiter from '../Assets/icons8-twitterx.svg';
// import XIcon from '@mui/icons-material/X';


export default function Footer() {
  return (
    <div className='Footer_Wrapper'>
        <div className='Footer_Inner_Wrapper'>
            <div className='row'>
                <div className='col-md-4 Footer_Address'>
                    <img src={Logo}></img>
                    <p>Your digital journey begins here, where pixels meet purpose and code turns dreams into reality.</p>
                    <p><LocationOnIcon></LocationOnIcon>No – 11, 1st Floor, 3rd Street, K K Nagar, Madurai ,Tamil Nadu, India</p>
                    <p><LocalPhoneIcon></LocalPhoneIcon><a href='tel:+91 63690 70815'>+91 63690 70815</a></p>
                    <p><EmailIcon></EmailIcon><a href='mailto:info@fiveytech.com'>info@fiveytech.com</a></p>
                </div>
                <div className='col-md-4 Footer_Address card2'>
                    <p className='Header'>Our Navigation</p>
                    <p><a onClick={e=>{window.location.href="/aboutus"}}>About us</a></p>
                    <p><a onClick={e=>{window.location.href="/services"}}>Services</a></p>
                    <p><a onClick={e=>{window.location.href="/contactus/careers"}}>Careers</a></p>
                    <p><a>Case Study</a></p>
                </div>
                <div className='col-md-4 Footer_Address card2'>
                    <p className='Header'>Social Media</p>
                    <p><a href='https://www.facebook.com/profile.php?id=61554694599414&mibextid=ZbWKwL' target='__blank'><FacebookIcon></FacebookIcon></a></p>
                    <p><a href='https://www.instagram.com/fiveytechnologies?igsh=ODA1NTc5OTg5Nw==' target='__blank'><InstagramIcon></InstagramIcon></a></p>
                    <p><a href='https://www.linkedin.com/company/fiveytech/' target='__blank'><LinkedInIcon></LinkedInIcon></a></p>
                    <p><a href='https://youtube.com/@FiveYTechnologies?si=ibrVJyuF5Ob8RqDf' target='__blank'><YouTubeIcon></YouTubeIcon></a></p>
                    <p><a href='https://twitter.com/fiveytech?t=dps8Wn6cdWZw2HIM5PNckg&s=08' target='__blank'><XIcon></XIcon></a></p>

                    {/* <p><a>Case Study</a></p> */}
                </div>
            </div>
            <p className='Footer_Policy'>
                <p>Copyright © 2023 Five Y Technologies All Rights Reserved</p>
            </p>
        </div>
    </div>
  )
}
